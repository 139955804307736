export function getYouTubeId(url) {
    var ID = '';
    if (!url || url === undefined || url === null) {
        return null;
    }
    url = url
        .replace(/(>|<)/gi, '')
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
    } else {
        ID = url;
    }
    return ID;
}

export function getPreviewUrl(youTubeId) {
    return `https://img.youtube.com/vi/${youTubeId}/maxresdefault.jpg`;
}

export function getEmbedUrl(youTubeId) {
    return `https://www.youtube.com/embed/${youTubeId}`;
}

export function getPlaylistEmbedUrl(listId) {
    return `https://www.youtube.com/embed/videoseries?list=${listId}`;
}
